// PersoReco.tsx

import React from 'react';

import '../../styles/Ads.css';
import '../../styles/size.css';
import '../../styles/color.css';

import { useLangContext } from '../../contexts/LangContext';

import ContactButton from '../Utilities/ContactButton';

import data from '../../data.json';

export default function PersoReco() {
    const lang = useLangContext().lang;

    return (
        <section id="PersoReco">
            <div className="container">
                <h2>
                    {data["PersoReco"]["title"][lang as keyof typeof data["PersoReco"]["title"]]}
                </h2>

                <p> 
                    {data["PersoReco"]["description"][lang as keyof typeof data["PersoReco"]["description"]]}
                </p>

                <ContactButton/>
            </div>
        </section>
    );
}