import React from 'react';

import { useLangContext } from '../../contexts/LangContext';

import Button from './Button';
import ALLLogo from '../Utilities/ALLLogo';
import TuneLogo from '../Utilities/TuneLogo';
import AdsLogo from '../Utilities/AdsLogo';

import '../../styles/NavBar.css';
import '../../styles/color.css';
import '../../styles/size.css';

import data from '../../data.json';

export const flag_data = {
    'flag' : {
        'fr' : '/assets/French.svg',
        'en' : '/assets/English.svg'
    },
    'alt' : {
        'fr' : 'Drapeau Français',
        'en' : 'English Flag'
    }
};

interface NavBarProps {
    pageRef: React.RefObject<HTMLDivElement>;
}

const NavBar: React.FC<NavBarProps> = ({pageRef}) => {
    const { lang, setLang } = useLangContext();

    const Lang = () => {
        setLang(lang === 'en'? 'fr' : 'en');
    };

    return (
        <nav>
            <Button to='/About' page='About' Logo={ALLLogo} pageRef={pageRef}/>
            
            <Button to='/' page='Tune' Logo={TuneLogo} pageRef={pageRef}/>

            <Button to='/Ads' page='Ads' Logo={AdsLogo} pageRef={pageRef}/>

            <div className='card'>
                <div className='logo'>
                    <img src={flag_data.flag[lang as keyof typeof flag_data['flag']]} 
                        alt={flag_data.alt[lang as keyof typeof flag_data['alt']]} 
                        id='flag' onClick={Lang} className='nav_button'/>
                </div>
                <span>
                    {data["Language"][lang as keyof typeof data['Language']]}
                </span>
            </div>
        </nav>
    );
}

export default NavBar;
