// FormWaitList.tsx

import React, { useState } from 'react';

import '../../styles/size.css'
import '../../styles/color.css'
import '../../styles/FormWaitList.css'

import { useLangContext } from '../../contexts/LangContext';

import { postEmail } from '../../helpers/addEmail'

import data from '../../data.json'


interface EmailFormProps {
    email : string;
    setEmail : (email: string) => void;
}

function EmailForm({email, setEmail} : EmailFormProps) {
    const lang = useLangContext().lang;

    return(
        <input type="email" 
            required={true}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder={data.WaitList[lang as keyof typeof data['WaitList']]}
            id="Form"/>
    );
}


function ConfirmButton() {
    const lang = useLangContext().lang;

    return(
        <button id="ConfirmButton" type="submit">
            {data.Confirm[lang as keyof typeof data['Confirm']]}
        </button>
    );
}

interface FormWaitListProps {
    setCode: (code: number) => void;
}

export default function FormWaitList({setCode}: FormWaitListProps) {
    const [email, setEmail] = useState('');

    const addEmail = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        const promise = postEmail(email);

        promise.then((res) => {
            setEmail('');
            setCode(1);
            if (res.data.code === 2){
                setEmail('');
                setCode(2);
            } else {
                setEmail('');
                setCode(1);
            }
        }).catch(err => {
            console.log(err);
            setCode(3);
        });
    }

    return (
        <form id="FormWaitList" onSubmit={addEmail}>
            <div>
                <EmailForm email={email} setEmail={setEmail}/>

                <ConfirmButton/>
            </div>
        </form>
    );
}
