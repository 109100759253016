// AnimationWrapper.tsx
// Help to animate the page when the user scroll to the element

import React, { useRef, useEffect, ReactNode } from 'react';

import '../../styles/size.css';
import '../../styles/color.css';
import '../../styles/About.css';


interface AnimationWrapperProps {
	children?: ReactNode;
    fast : boolean;
    previousRef? : React.RefObject<HTMLDivElement>;
    id?: string;
    className?: string;
}


function AnimationWrapper({ children, previousRef, fast = false, id, className} : AnimationWrapperProps) {

    const newRef = useRef<HTMLDivElement>(null);
    const ref =  previousRef? previousRef : newRef;

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && ref.current !== null) {
                    ref.current.classList.remove('noDisplay');

                    if (fast) {
                        ref.current.classList.remove('animatedBlock');
                        ref.current.classList.add('fastBlock');
                    } else {
                        ref.current.classList.add('animatedBlock');
                        ref.current.classList.remove('fastBlock');
                    }
                } else {
                    if (ref.current !== null) {
                        ref.current.classList.add('noDisplay');
                    }
                }
            });
        }, { rootMargin: "100% 0px 0px 0px", threshold : 0.2});

        if (ref.current !== null) {
            observer.observe(ref.current);
        }

        // nettoyage de l'observer lorsqu'il n'est plus nécessaire
        return () => observer.disconnect();
    });

    return (
        <div className={"animationWrapper " + className} ref={ref} id={id}>
            {children}
        </div>
    );
}

export default AnimationWrapper;
