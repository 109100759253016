// JoinUs.tsx

import React from 'react';

import '../../styles/Ads.css';
import '../../styles/size.css';
import '../../styles/color.css';

import { useLangContext } from '../../contexts/LangContext';

import data from '../../data.json';
import ContactButton from '../Utilities/ContactButton';
import JoinUsLogo from '../Utilities/JoinUsLogo';

export default function JoinUs() {
    const lang = useLangContext().lang;

    return(
        <section id="JoinUs">
            <div className="container">
                <h2>
                    {data["JoinUs"]["title"][lang as keyof typeof data["JoinUs"]["title"]]}
                </h2>

                <p>
                    {data["JoinUs"]["description"][lang as keyof typeof data["JoinUs"]["description"]]}
                </p>

                <ContactButton/>
            </div>

            <JoinUsLogo/>

        </section>
    );
}