import React from 'react';
import { Link } from 'react-router-dom';

import { usePageContext } from '../../contexts/PageContext';

import "../../styles/NavBar.css";

interface ButtonProps {
    page: string;
    Logo: React.FC<{active: boolean}>;
    to: string;
    pageRef: React.RefObject<HTMLDivElement>;
}

const Button: React.FC<ButtonProps> = ({page, Logo, to, pageRef}) => {
    const currentPage = usePageContext().page;

    function resetScroll() {
        if (pageRef.current && pageRef.current !== null) {
            pageRef.current.scrollTo(0, 0);
        }
    }

    return(
        <Link to={to} className='nav_button' onClick={resetScroll}>
            <div className='card'>
                <div className='logo'>
                    <Logo active={currentPage === page}/>
                </div>
                <span>
                    {page}
                </span>
            </div>
        </Link>
    )
};

export default Button;
