import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';

import '../../styles/Tune.css'
import '../../styles/size.css'
import '../../styles/color.css'

import { usePageContext } from '../../contexts/PageContext';
import FormWaitList from './FormWaitList';
import TuneDescription from './TuneDescription';
import Popup from './Popup';

export default function Tune() {
    const [registred, setCode] = useState(0);

    const setPage = usePageContext().setPage;

    const tuneRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (tuneRef.current && tuneRef.current !== null) {
            tuneRef.current.scrollTop = 0;
        }

        setPage('Tune');
    });

    return (
        <div id="Tune" ref={tuneRef}>
            <Helmet>
                <title>Tune</title>
                <link rel='canonical' href="htttps://all-enterprises.com/"/>
                <link rel="apple-touch-icon" sizes="180x180" href="/Tune_favicon/apple-touch-icon.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/Tune_favicon/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/Tune_favicon/favicon-16x16.png"/>
                <link id="favicon" rel="icon" href="/Tune_favicon/favicon.ico"/>
                <link rel="manifest" href="/Tune_favicon/site.webmanifest"/>
            </Helmet>

            <Popup registred={registred} setCode={setCode}/>
            <TuneDescription/>
            <FormWaitList setCode={setCode}/>
        </div>
    );
}
