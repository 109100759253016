// App.tsx

import React, { createRef, useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { Helmet } from 'react-helmet';

import '../styles/size.css';
import '../styles/color.css';
import '../styles/App.css';

import { useLangContext, LangContextProvider } from '../contexts/LangContext';
import { usePageContext, PageContextProvider } from '../contexts/PageContext';
import { useScrollContext, ScrollContextProvider} from '../contexts/ScrollContext';

import Tune from './Tune/Tune';
import About from './About/About';
import Ads from './Ads/Ads';
import NavBar from './NavBar/NavBar';
import Copyright from './Utilities/Copyright';
import ContactButton from './Utilities/ContactButton';


export default function App() {
    return (
        <BrowserRouter>
            <LangContextProvider>
                <PageContextProvider>
                    <ScrollContextProvider>
                        <Content/>
                    </ScrollContextProvider>
                </PageContextProvider>
            </LangContextProvider>
        </BrowserRouter>
    );
}


function Content() {
    const lang = useLangContext().lang;
    const page = usePageContext().page;
    const setScroll  = useScrollContext().setScroll;

    const wideScreen = useMediaQuery('(min-width: 800px), (orientation : landscape)');

    const pageRef = createRef<HTMLDivElement>();

    useEffect( () => {
        const current = pageRef.current;

        function handleScroll() {
            if (current)
            setScroll(current.scrollTop / Math.round(current.scrollHeight - current.getBoundingClientRect()['height']));
        }

        if (current && current !== null && page === 'Ads' && !wideScreen) {
            current.addEventListener('scroll', handleScroll, true);

            return () => {
                if (current) {
                    current.removeEventListener('scroll', handleScroll, true);
                }
            };
        } 
        
        if (wideScreen) {
            setScroll(null);
        } else {
            setScroll(0);
        }

    }, [page, wideScreen, setScroll, pageRef]);


    return (
        <>
            <Helmet>
                <html lang={lang}/>
                <meta name="theme-color" content="#000000"/>
            </Helmet>

            <header>
                <NavBar pageRef={pageRef}/>
            </header>

            <div className="page" ref={pageRef}> 
                <Routes>
                    <Route path="/" element={<Tune/>}/>
                    <Route path="/About" element={<About/>}/>
                    <Route path="/Ads" element={<Ads/>}/>
                </Routes>
                
                <footer>
                    <Copyright/>
                </footer>
            </div>

            {page === 'Ads' && !wideScreen ? <ContactButton/> : null}
        </>
    )
}
