import React from 'react';

import '../../styles/Ads.css';
import '../../styles/size.css';
import '../../styles/color.css';

import ALLLogo from '../Utilities/ALLLogo';
import PubPresentation from './PubPresentation';
import ContactButton from '../Utilities/ContactButton';

import { useLangContext } from '../../contexts/LangContext';

import data from '../../data.json';

export default function NextClient() {

    const lang = useLangContext().lang;

    return (
        <section id="NextClients">
            <div className='container'>
                <div className="description">
                    <div id="AdsHead">
                        <ALLLogo/>
                        <h1>
                            {data.Ads[lang as keyof typeof data.Ads]}
                        </h1>
                    </div>

                    <h2>
                        {data["NextClients"]["title"][lang as keyof typeof data["NextClients"]["title"]]}
                    </h2>
                    
                    <p>
                        {data["NextClients"]["description"][lang as keyof typeof data["NextClients"]["description"]]}
                    </p>
                </div>
                
                <ContactButton/>
            </div>

            <PubPresentation/>
        </section>
    );
}