import React from 'react';

import '../../styles/Copyright.css'
import '../../styles/size.css'
import '../../styles/color.css'

import { useLangContext } from '../../contexts/LangContext';

import data from '../../data.json'

export default function Copyright() {
    const lang = useLangContext().lang;

    return (
        <div id="Copyright">
            {data.Copyright[lang as keyof typeof data.Copyright]}
        </div>
    );
}
