// JoinUsLogo.tsx

import React from 'react'

import '../../styles/ALLButton.css'
import '../../styles/size.css'
import '../../styles/color.css'

export default function JoinUsLogo() {
    return(
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1451.000000 1189.000000"
        preserveAspectRatio="xMidYMid meet" id="JoinUsLogo">
            <g transform="translate(0.000000,1189.000000) scale(0.100000,-0.100000)">
                <path d="M6985 11808 c-322 -39 -625 -188 -855 -418 -392 -394 -528 -967 -355
                -1494 75 -229 197 -419 382 -597 214 -205 466 -332 768 -385 105 -19 414 -26
                553 -13 335 32 635 168 875 398 288 277 438 609 454 1006 10 245 -37 466 -146
                690 -75 153 -161 274 -281 395 -204 205 -452 337 -750 401 -90 19 -138 23
                -340 24 -129 1 -266 -2 -305 -7z"/>
                <path d="M5505 11072 c-232 -97 -414 -270 -520 -495 -158 -332 -129 -742 76
                -1073 65 -105 242 -289 339 -353 185 -121 412 -191 615 -191 89 0 135 24 119
                63 -2 6 -48 35 -102 62 -329 169 -569 637 -589 1146 -12 305 47 550 178 742
                29 42 42 73 43 97 1 33 -1 35 -34 37 -22 2 -67 -11 -125 -35z"/>
                <path d="M8844 11095 c-10 -25 4 -63 47 -125 129 -189 188 -435 176 -739 -16
                -404 -160 -766 -402 -1006 -73 -73 -108 -99 -187 -140 -54 -27 -100 -56 -102
                -62 -41 -104 338 -74 588 47 130 63 204 117 321 235 198 200 301 415 336 696
                13 108 -1 287 -30 403 -78 298 -304 554 -595 671 -92 38 -143 44 -152 20z"/>
                <path d="M1049 9979 c-150 -9 -228 -27 -318 -74 -140 -74 -331 -273 -424 -445
                -81 -148 -167 -465 -197 -720 -31 -264 -39 -419 -46 -860 -4 -265 -2 -480 5
                -595 10 -167 26 -480 51 -1020 5 -121 12 -247 15 -280 40 -463 54 -575 100
                -790 18 -88 43 -225 55 -305 27 -181 56 -272 120 -380 61 -102 93 -136 228
                -245 275 -221 445 -308 1192 -609 140 -56 291 -123 335 -148 261 -148 488
                -286 545 -329 215 -166 558 -494 671 -642 32 -43 79 -120 103 -170 63 -134
                168 -404 211 -542 21 -66 61 -196 91 -290 108 -344 196 -675 243 -912 42 -208
                61 -255 133 -326 102 -100 259 -163 553 -223 237 -49 902 -3 1180 81 62 19
                162 46 220 60 150 35 395 135 534 218 168 99 175 106 222 205 41 85 42 90 56
                262 33 399 27 1058 -12 1500 -53 584 -90 813 -175 1096 -54 181 -70 219 -142
                343 -56 97 -79 124 -272 317 -151 150 -232 223 -283 255 -39 24 -159 103 -265
                175 -433 294 -604 387 -1513 820 -666 317 -1080 484 -1411 568 -144 37 -340
                13 -493 -59 -40 -19 -81 -35 -90 -35 -27 0 -109 56 -163 112 -152 157 -182
                272 -198 773 -6 171 -14 362 -20 425 -5 63 -12 198 -15 300 -3 102 -9 196 -12
                210 -4 14 -7 381 -8 815 l-1 790 -27 120 c-59 251 -173 409 -364 504 -121 61
                -163 66 -414 50z"/>
                <path d="M13162 9974 c-70 -18 -165 -66 -230 -115 -117 -88 -203 -240 -249
                -434 l-27 -120 -1 -790 c-1 -434 -4 -801 -8 -815 -3 -14 -9 -108 -12 -210 -3
                -102 -10 -237 -15 -300 -6 -63 -14 -254 -20 -425 -16 -501 -46 -616 -198 -773
                -54 -56 -136 -112 -163 -112 -9 0 -50 16 -90 35 -153 72 -349 96 -493 59 -331
                -84 -745 -251 -1411 -568 -909 -433 -1080 -526 -1513 -820 -106 -72 -226 -151
                -265 -175 -51 -32 -132 -105 -283 -255 -193 -193 -216 -220 -272 -317 -72
                -124 -88 -162 -142 -343 -85 -283 -122 -512 -175 -1096 -39 -442 -45 -1101
                -12 -1500 14 -172 15 -177 56 -262 47 -99 54 -106 222 -205 139 -83 384 -183
                534 -218 58 -14 158 -41 220 -60 278 -84 943 -130 1180 -81 294 60 451 123
                553 223 72 71 91 118 133 326 47 237 135 568 243 912 30 94 70 224 91 290 43
                138 148 408 211 542 24 50 71 127 103 170 113 148 456 476 671 642 57 43 284
                181 545 329 44 25 195 92 335 148 747 301 917 388 1192 609 135 109 167 143
                228 245 64 108 93 199 120 380 12 80 37 217 55 305 46 215 60 327 100 790 3
                33 10 159 15 280 25 540 41 853 51 1020 7 115 9 330 5 595 -7 441 -15 596 -46
                860 -30 255 -116 572 -197 720 -93 171 -284 371 -424 445 -94 49 -162 64 -344
                75 -198 12 -208 12 -273 -6z"/>
                <path d="M6100 8698 c-354 -76 -836 -300 -1295 -603 -925 -609 -1624 -1422
                -1625 -1888 0 -99 -4 -96 253 -193 95 -35 188 -71 207 -79 19 -8 250 -96 514
                -196 580 -220 551 -218 526 -25 -16 122 -16 363 0 506 34 311 131 618 301 960
                252 506 629 965 1122 1367 74 61 127 112 127 122 0 40 -38 49 -130 29z"/>
                <path d="M8293 8699 c-27 -28 -10 -50 115 -152 492 -402 869 -861 1121 -1367
                170 -342 267 -649 301 -960 16 -143 16 -384 0 -506 -14 -113 -12 -129 24 -145
                21 -10 86 12 502 170 264 100 495 188 514 196 19 8 112 44 207 79 257 97 253
                94 253 193 -1 567 -1014 1605 -2135 2189 -365 190 -853 354 -902 303z"/>
                <path d="M7140 8670 c-392 -41 -851 -298 -1246 -697 -512 -519 -873 -1200
                -994 -1879 -26 -143 -40 -546 -22 -581 8 -14 42 -32 104 -54 105 -37 326 -148
                466 -233 541 -328 1070 -805 1352 -1220 39 -58 86 -116 102 -128 17 -12 63
                -27 102 -34 136 -25 520 -13 581 18 30 15 71 63 136 160 229 340 697 779 1156
                1086 226 151 491 294 651 351 62 22 96 40 104 54 18 35 4 438 -22 581 -57 320
                -162 632 -317 936 -516 1014 -1438 1716 -2153 1640z"/>
            </g>
        </svg>

    )
}
