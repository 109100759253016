import React from 'react'

import '../../styles/TuneDescription.css'
import '../../styles/size.css'

import { useLangContext } from '../../contexts/LangContext'

import TuneLogo from '../Utilities/TuneLogo'

import data from '../../data.json'

export default function TuneDescription() {
    const lang = useLangContext().lang;

    return (
        <div id="TuneDescription">
            <TuneLogo active={false}/>

            <div id="TuneDescription_Description">
                
                <h1>
                    Tune
                </h1>

                <h2>
                    {data.Tune_Value[lang as keyof typeof data['Tune_Value']]}
                </h2>
                
                {data.Tune_Description.map((P, index) => (
                    <p className='description' key={index}>{P[lang as keyof typeof P]}</p>
                ))}
            </div>
        </div>
    );
}