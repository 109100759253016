// Ads.tsx

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { usePageContext } from '../../contexts/PageContext';

import '../../styles/Ads.css';
import '../../styles/size.css';
import '../../styles/color.css';

import ContactButton from '../Utilities/ContactButton';
import NextClients from './NextClients';
import PersoReco from './PersoReco';
import JoinUs from './JoinUs';

export default function Ads() {
    const setPage = usePageContext().setPage;

    useEffect( () => {
        setPage('Ads');
    });

    return(
        <div id="Ads">
            <Helmet>
                <title>ALL Ads</title>
                <link rel='canonical' href="htttps://all-enterprises.com/Ads"/>
                <link rel="apple-touch-icon" sizes="180x180" href="/ALL_favicon/apple-touch-icon.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/ALL_favicon/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/ALL_favicon/favicon-16x16.png"/>
                <link rel="manifest" href="/ALL_favicon/site.webmanifest"/>
            </Helmet>

            <nav>
                <ContactButton/>
            </nav>
            
            <NextClients/>
            <PersoReco/>
            <JoinUs/>

        </div>
    )
}
