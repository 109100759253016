import React, { useRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import '../../styles/size.css';
import '../../styles/color.css';
import '../../styles/About.css';

import { useLangContext } from '../../contexts/LangContext';
import { usePageContext } from '../../contexts/PageContext';

import data from '../../data.json';

import AnimationWrapper from './AnimationWrapper';
import Banner from './Banner';

import LinkedInLogo from "../Utilities/LinkedInLogo";

export default function About() {
    
    const { setPage, page } = usePageContext();
    const lang = useLangContext().lang;

    const contactRef = useRef<HTMLDivElement>(null);

    const [fast, setFast ] = useState(false);


    useEffect( () => {
        if (page === 'Contact') {
            setFast(true);
        } else {
            setFast(false);
        }

        setPage('About');
    }, [page, setPage]);


    useEffect( () => {
        if (contactRef.current && page === 'Contact') {
            contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'center'});
        }

    }, [page]);

    return (
        <div id="About">
            <Helmet>
                <title>{data["About"][lang as keyof typeof data['About']]} ALL</title>
                <link rel='canonical' href="htttps://all-enterprises.com/About"/>
                <link rel="apple-touch-icon" sizes="180x180" href="/ALL_favicon/apple-touch-icon.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/ALL_favicon/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/ALL_favicon/favicon-16x16.png"/>
                <link rel="icon" href="/ALL_favicon/favicon.ico"/>
                <link rel="manifest" href="/ALL_favicon/site.webmanifest"/>
            </Helmet>


            <div id="Banner">
                <Banner/>
            </div>
            
            <div className='description'>
                <AnimationWrapper fast={fast} id="Values" className="widget">
                    <div id="ValuesContainer">
                        <div className="left">
                            <div className="textBlocDescription">
                                {data.AboutWidgets.Values.description[lang as keyof typeof data.AboutWidgets.Values.description]}
                            </div>
                        </div>

                        <div className="right">
                            <img src="/assets/Robot.png" alt="Robot Illustration"/>
                        </div>
                    </div>
                </AnimationWrapper>

                <AnimationWrapper fast={fast} id='Contact' className='widget' previousRef={contactRef}>
                    <div>
                        <h2 id="contactTitle">
                            {data.AboutWidgets.Contact.title[lang as keyof typeof data.AboutWidgets.Contact.title]}
                        </h2>
                        <div className="textBlocDescription">
                            <div id='contactContainer'>
                                <a href={"mailto:" + data.AboutWidgets.Contact.email + "?subject=Contact"} id="Email" >
                                    {data.AboutWidgets.Contact.email}
                                </a>
                            </div>
                        </div>
                    </div>
                </AnimationWrapper>

                <AnimationWrapper fast={fast} id='SocialMedia' className='widget'>
                    <button id="LinkedInButton" onClick={() => window.open('https://www.linkedin.com/company/allenterprise/')}>
                        <LinkedInLogo/>
                    </button>
                </AnimationWrapper>

            </div>

            <div ref={contactRef}/>
        </div>
    )
}
