// Popup.tsx

import React, { useState } from 'react';

import '../../styles/Popup.css';
import '../../styles/size.css';
import '../../styles/color.css';

import data from '../../data.json';

import { useLangContext } from '../../contexts/LangContext';

interface LogoProps {
    result : number;
}

// function Logo(props) {
const Logo: React.FC<LogoProps> = ({result}) => {
    if (result === 3){
        return(
            <svg version="1.0" viewBox="0 0 1269 1270" preserveAspectRatio="xMidYMid meet">
                <g className="Email_State" transform="translate(0, 1270) scale(0.1, -0.1)">
                    <path d="M5795 12655 c-750 -73 -1410 -245 -2055 -537 -1325 -600 -2404 -1638
                    -3056 -2940 -350 -699 -552 -1399 -640 -2213 -21 -203 -30 -807 -15 -1031 99
                    -1434 653 -2750 1602 -3809 140 -156 423 -435 569 -561 1065 -918 2360 -1447
                    3760 -1535 230 -14 764 -6 980 15 962 96 1853 389 2660 874 1781 1070 2924
                    2940 3061 5005 15 238 7 862 -15 1057 -166 1474 -760 2748 -1764 3781 -941
                    969 -2153 1604 -3490 1829 -372 63 -485 71 -992 75 -341 3 -502 0 -605 -10z
                    m-2155 -2651 c90 -23 181 -65 253 -118 34 -25 595 -578 1247 -1230 785 -786
                    1192 -1186 1205 -1186 13 0 417 397 1195 1176 646 646 1200 1195 1230 1218
                    231 178 535 215 796 96 306 -138 494 -470 454 -797 -22 -176 -83 -306 -213
                    -453 -41 -47 -586 -596 -1211 -1220 -625 -624 -1136 -1139 -1136 -1145 0 -5
                    539 -550 1199 -1210 659 -660 1212 -1220 1228 -1245 96 -143 135 -271 135
                    -440 1 -345 -205 -631 -532 -741 -169 -57 -367 -51 -530 14 -143 58 -124 40
                    -1385 1299 -666 665 -1217 1208 -1225 1208 -8 0 -553 -537 -1210 -1194 -657
                    -656 -1215 -1207 -1240 -1223 -69 -46 -158 -89 -240 -115 -65 -20 -95 -23
                    -220 -22 -130 0 -153 3 -225 27 -132 44 -219 99 -321 201 -76 77 -99 107 -138
                    186 -62 126 -86 226 -86 354 0 161 41 303 126 433 24 38 457 478 1236 1258
                    659 660 1198 1207 1198 1215 0 8 -543 559 -1208 1225 -908 910 -1216 1224
                    -1241 1267 -77 132 -111 258 -111 408 0 218 72 391 225 545 119 121 247 189
                    410 221 87 17 246 11 335 -12z"/>
                </g>
            </svg>)
    } else {
        return (
            <svg version="1.0" viewBox="0 0 1096 1096" preserveAspectRatio="xMidYMid meet">
                <g className="Email_State" transform="translate(0, 1096) scale(0.1, -0.1)">
                    <path d="M4995 10925 c-1337 -133 -2527 -702 -3434 -1641 -881 -912 -1403
                    -2054 -1523 -3329 -16 -176 -16 -785 0 -950 57 -569 172 -1054 366 -1545 76
                    -191 272 -589 379 -767 359 -599 806 -1104 1363 -1539 144 -112 475 -333 644
                    -429 655 -370 1374 -599 2145 -681 200 -21 671 -29 895 -15 1169 74 2253 509
                    3170 1270 151 125 530 504 658 657 302 362 514 688 722 1109 204 414 329 759
                    430 1195 151 653 176 1412 69 2079 -156 980 -567 1880 -1208 2651 -129 156
                    -527 553 -681 680 -355 294 -677 503 -1080 704 -642 319 -1325 506 -2025 556
                    -193 14 -732 10 -890 -5z m3952 -2589 c217 -58 390 -232 448 -452 24 -89 21
                    -242 -5 -329 -52 -166 137 31 -2624 -2732 -2251 -2252 -2544 -2543 -2571
                    -2543 -26 0 -180 150 -1308 1278 -704 702 -1312 1315 -1353 1361 -87 98 -135
                    191 -158 303 -20 97 -20 159 0 256 44 220 215 406 434 474 58 18 99 23 180 23
                    81 0 122 -5 180 -23 155 -48 135 -31 1090 -987 793 -794 918 -915 947 -915 4
                    0 946 938 2093 2084 1531 1531 2102 2095 2148 2125 65 41 157 76 240 91 60 11
                    192 4 259 -14z"/>
                </g>
            </svg>
        )
    } 
}

interface PopupProps {
    registred : number;
    setCode : (code : number) => void;
}


const Popup: React.FC<PopupProps> = ({registred, setCode}) => {
    const lang = useLangContext().lang;

    const [isWaiting, setWait] = useState<NodeJS.Timeout | null>(null);
    
    const resetCode = () => {
        setCode(0);
        setWait(null);
    }

    const getMsg = (lang : string) => {
        if (registred === 1){
            return(data.Registred.one[lang as keyof typeof data.Registred.one]);
        }
        if (registred === 2){
            return(data.Registred.two[lang as keyof typeof data.Registred.two]);
        }
        if (registred === 3){
            return(data.Error[lang as keyof typeof data.Error]);
        }
        return;
    }

    if (!(registred === 0)){
        if (isWaiting === null){
            setWait(setTimeout(resetCode, 3000));
        }
        return (
            <div id="Popup">
                <p>
                    {getMsg(lang)}
                </p>
                <Logo result={registred}/>
            </div>
            ); } 
    else {
        return;
    }
}

export default Popup;
