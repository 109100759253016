import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import './styles/size.css';

import App from './components/App';

const rootElement = document.getElementById('root');

if (!rootElement) {
    throw new Error("No root element found");
}

const root = ReactDOM.createRoot(rootElement);

root.render(
	<App/>
);
